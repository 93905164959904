import classNames from "classnames";
import React from "react";
import HYLink from "../../link/link";
import styles from "./index.module.scss";

interface IPageHeaderPopMenuItem {
  name: string;
  link: string;
}

interface IProps {
  items: IPageHeaderPopMenuItem[];
}

const PageHeaderPopMenu: React.FC<IProps> = (props) => {
  const { items } = props;

  return (
    <div className={classNames(styles.container, "flex-col")}>
      {items.map((item, index) => (
        <HYLink
          key={index}
          className={styles.menu}
          href={item.link}
          //onClick={() => window.location.assign(item.link)}
        >
          {item.name}
        </HYLink>
      ))}
    </div>
  );
};

export default PageHeaderPopMenu;
