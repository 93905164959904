import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import iAddress from "@/public/common/page-footer/address.png";
import iPhone from "@/public/common/page-footer/phone.png";
import iEmail from "@/public/common/page-footer/email.png";
import iReport from "@/public/common/page-footer/report.png";
import iLicense from "@/public/common/page-footer/license.png";
import HYLink from "../link/link";
import AppRouter from "@/common/app/router";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";
import { ListModel } from "@/common/defines/list-model";
import { WebLinkModel } from "@/modules/others/api/models/web-link-model";

const oss = getOssAssetsGenerator("page-footer/");

interface IProps {
  webLinkData?: ListModel<WebLinkModel>;
}

const PageFooter: React.FC<IProps> = (props) => {
  const { webLinkData } = props;

  // 左侧
  const leftUI = (
    <div className={styles.left}>
      <div className={styles.logo} />
      <div className={styles.leftItem}>
        <img src={iAddress} alt="" />
        <div>四川省成都市成华区台州商人大厦1416</div>
      </div>
      <div className={styles.leftItem}>
        <img src={iPhone} alt="" />
        <div>17760536361</div>
      </div>
      <div className={styles.leftItem}>
        <img src={iEmail} alt="" />
        <div>huiyitop@163.com</div>
      </div>
    </div>
  );

  // 中间菜单
  const midUI = (
    <div className={classNames(styles.mid, "flex-row")}>
      <div className={styles.row}>
        <div className={styles.columnTitle}>关于汇蚁</div>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.about}>
          公司简介
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.cooperate}>
          商务合作
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.feedback}>
          投诉建议
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.recruit}>
          人才招聘
        </HYLink>
      </div>
      <div className={styles.row}>
        <div className={styles.columnTitle}>帮助中心</div>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.feedback}>
          意见反馈
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.CMS.detail(27)}>
          入驻京东条件
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.CMS.detail(14)}>
          商标转让流程
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.CMS.detail(26)}>
          商标转让资料
        </HYLink>
      </div>
      <div className={styles.row}>
        <div className={styles.columnTitle}>隐私安全</div>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.disclaimer}>
          免责声明
        </HYLink>
        <HYLink className={styles.columnItem} target="_blank" href={AppRouter.Others.privacy}>
          隐私声明
        </HYLink>
      </div>
    </div>
  );

  // 右侧
  const rightUI = (
    <div className={classNames(styles.right, "flex-row")}>
      <div className={classNames(styles.rightItem, "flex-col flex-cross-axis-center")}>
        <img src={oss("wechat.png")} alt="" />
        <div>扫码添加企业微信</div>
      </div>
      <div className={classNames(styles.rightItem, "flex-col flex-cross-axis-center")}>
        <img src={oss("mini-app.png")} alt="" />
        <div>汇蚁网小程序</div>
      </div>
    </div>
  );

  return (
    <div className={styles.footer}>
      <div className={styles.top}>
        <div className={classNames(styles.topContent, "flex-row flex-main-axis-between")}>
          {leftUI}
          {midUI}
          {rightUI}
        </div>
      </div>
      <div className={classNames(styles.bottom, "flex-col flex-cross-axis-center")}>
        <div>
          copyright c 2021 四川汇蚁融创网络科技有限公司版权保留一切权力 备案号:蜀ICP惫2021024633号-1
          川公网安备51010802032410号
        </div>
        <div className="flex-row-center">
          <img
            className={styles.link}
            src={iReport}
            alt=""
            onClick={() => window.open("https://www.12377.cn/")}
          />
          {/* <img className={styles.link} src={iPolice} alt="" /> */}
          <img
            className={styles.link}
            src={iLicense}
            alt=""
            onClick={() => window.open(AppRouter.Others.lincense)}
          />
        </div>
        {webLinkData && (
          <div className={classNames([styles.linkContainer, "flex-row-warp"])}>
            {webLinkData.list.map((item, index) => (
              <a key={index} className={styles.link} target="_blank" href={item.url}>
                {item.name}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageFooter;
