import AppRouter from "@/common/app/router";
import { AppActions, useAppDispatch, useAppSelector } from "@/common/redux";
import { Popover } from "antd";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import HYLink from "../link/link";
import styles from "./index.module.scss";
import PageHeaderPopMenu from "./pop-menu";

interface IProps {
  mode?: "black" | "white";
  style?: CSSProperties;
  className?: string;
}

interface IMainMenu {
  name: string;
  link: string;
  tag?: "hot" | "new";
  hoverContent?: ReactNode;
}

const PageHeader: React.FC<IProps> = (props) => {
  const { mode = "black", style, className } = props;
  const router = useRouter();
  const user = useAppSelector((s) => s.user);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>();
  const [userName, setUserName] = useState<string>();

  useEffect(() => {
    if (user.token && user.user) {
      setUserName(user.user.mobile);
    } else {
      setUserName(undefined);
    }
  }, [user]);

  const onSearch = useCallback((name?: string) => {
    window.open(AppRouter.TM.transfer({ tmname: name }));
  }, []);

  // 顶部
  const topUI = useMemo(() => {
    let child: ReactNode;
    if (userName) {
      child = (
        <>
          <div className={styles.text}>{userName}</div>
          <div
            className={styles.item}
            style={{ borderLeft: "none" }}
            onClick={() => dispatch(AppActions.User.logout())}
          >
            登出
          </div>
        </>
      );
    } else {
      child = (
        <>
          <HYLink className={classNames(styles.item, styles.login)} href={AppRouter.User.login()}>
            立即登录
          </HYLink>
          <HYLink
            className={classNames(styles.item, styles.login)}
            href={AppRouter.User.login({ type: "signup" })}
          >
            免费注册
          </HYLink>
        </>
      );
    }

    return (
      <div
        className={classNames(styles.top, "flex-row flex-cross-axis-center")}
      >
        <div
          className={classNames(
            styles.content,
            "flex-row flex-main-axis-between flex-cross-axis-center"
          )}
        >
          {/* 左侧 */}
          <div className="flex-row flex-cross-axis-center">
            <div className={styles.text}>Hi，欢迎来到汇蚁网</div>
            <div
              style={{ marginLeft: 20 }}
              className={classNames(
                styles.itemList,
                "flex-row flex-cross-axis-center"
              )}
            >
              {child}
            </div>
          </div>
          {/* 右侧 */}
          <div className="flex-row flex-cross-axis-center">
            <div
              className={classNames(
                styles.itemList,
                "flex-row flex-cross-axis-center"
              )}
            >
              <HYLink className={styles.item} href={AppRouter.User.Center.home}>
                个人中心
              </HYLink>
              <HYLink className={styles.item} href={AppRouter.Others.about}>
                关于汇蚁
              </HYLink>
              <HYLink className={styles.item} href={AppRouter.Others.feedback}>
                投诉建议
              </HYLink>
            </div>
            <div
              style={{ marginLeft: 40 }}
              className="flex-row flex-cross-axis-center"
            >
              <div className={styles.phoneIcon} />
              <div style={{ marginLeft: 10 }} className={styles.text}>
                服务热线：
              </div>
              <div style={{ marginLeft: 10 }} className={styles.phoneNumber}>
              17760536361
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [userName, dispatch]);

  // 创建主导航栏菜单
  const createMainMenu = useCallback(
    (item: IMainMenu, index: number) => {
      const child = (
        <div key={index} className={classNames(styles.item, "flex-row-center")}>
          <HYLink href={item.link}>{item.name}</HYLink>
          {item.tag === "hot" && <div className={styles.hot} />}
          {item.tag === "new" && <div className={styles.new} />}
          {item.hoverContent && (
            <img
              className={styles.arrow}
              src={
                mode === "white"
                  ? require("@/assets/common/page-header/arrow-down-white.png")
                  : require("@/assets/common/page-header/arrow-down.png")
              }
              alt=""
            />
          )}
        </div>
      );

      if (item.hoverContent) {
        return (
          <Popover key={index} content={item.hoverContent}>
            {child}
          </Popover>
        );
      }

      return child;
    },
    [mode]
  );

  // 主导航栏
  const navigation = useMemo(() => {
    const config: IMainMenu[] = [
      { name: "首页", link: AppRouter.home },
      {
        name: "京东入驻",
        link: AppRouter.Settled.business("jd"),
        tag: "hot",
        hoverContent: (
          <PageHeaderPopMenu
            items={[
              { name: "京东慧采", link: AppRouter.Settled.business("jdhc") },
              { name: "京东自营", link: AppRouter.Settled.business("jdzy") },
              { name: "京东Pop", link: AppRouter.Settled.business("jdpop") },
            ]}
          />
        ),
      },
      { name: "天猫入驻", link: AppRouter.Settled.business("tmall"), tag: "new" },
      { name: "抖小店入驻", link: AppRouter.Settled.business("douyin") },
      { name: "电商代运营", link: AppRouter.Agent.business },
      { name: "商标转让", link: AppRouter.TM.transfer() },
      {
        name: "知识产权",
        link: AppRouter.TM.business,
        hoverContent: (
          <PageHeaderPopMenu
            items={[
              { name: "商标服务", link: AppRouter.TM.business },
              { name: "专利服务", link: AppRouter.Intellectual.patent },
              { name: "版权服务", link: AppRouter.Intellectual.copyrights },
              { name: "品牌授权", link: AppRouter.Intellectual.brandAuth },
            ]}
          />
        ),
      },
    ];

    return (
      <div className={classNames(styles.navigation, "flex-row flex-cross-axis-center")}>
        <div
          className={classNames(
            styles.content,
            "flex-row flex-main-axis-between flex-cross-axis-center"
          )}
        >
          {/* Logo */}
          <HYLink className={styles.logo} href={AppRouter.home} />
          {/* 主菜单 */}
          <div
            style={{ marginTop: 10 }}
            className={classNames(styles.itemList, "flex-row flex-cross-axis-center")}
          >
            {config.map((item, index) => createMainMenu(item, index))}
          </div>
          {/* 商标搜索框 */}
          <div
            style={{ marginTop: 10 }}
            className={classNames(styles.searchBar, "flex-row flex-cross-axis-center")}
          >
            <div className={styles.searchTitle}>商标</div>
            <input
              type="text"
              placeholder="输入搜索关键字"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.keyCode === 13) {
                  onSearch(search);
                }
              }}
            />
            <div className={styles.searchIcon} onClick={() => onSearch(search)} />
          </div>
        </div>
      </div>
    );
  }, [createMainMenu, search, onSearch]);

  return (
    <div
      style={style}
      className={classNames(
        styles.header,
        mode === "black" ? styles.black : styles.white,
        className
      )}
    >
      {topUI}
      {navigation}
    </div>
  );
};

export default PageHeader;
