import React, { CSSProperties, HTMLAttributeAnchorTarget, PropsWithChildren } from "react";

interface IHYLinkProps {
  style?: CSSProperties;
  className?: string;
  target?: HTMLAttributeAnchorTarget;
  href?: string;
  onClick?: () => void;
}

const HYLink: React.FC<PropsWithChildren<IHYLinkProps>> = (props) => {

  const { style, className, target, href, onClick } = props;

  // 判定是否是自身域名
  let isSelf = false;
  if (href && (href.startsWith("/") || href.includes("huiyitop.com"))) {
    isSelf = true;
  }

  // rel值 
  var rel: string | undefined;
  if (!isSelf) {
    rel = "nofollow";
    if (target === '_blank') {
      rel += " noopener noreferrer";
    }
  }

  return (
    <a
      style={style}
      className={className}
      target={target}
      href={href}
      rel={rel}
      onClick={onClick}
    >
      {props.children}
    </a>
  );
};

export default HYLink;
