
// OSS基地址
export const OSSHost = 'https://eshow-tianyu.oss-cn-chengdu.aliyuncs.com/';

// OSS前台网站资源路径基地址
export const OSSAssetURL = OSSHost + "assets/frontend/v3.0";

// 拼接OSS前台网站资源路径
export function getOssAssets(path: string) : string {
  return OSSAssetURL + "/" + path;
}

// 生成某个模块资源的路径生成器
type OssURLGenerator = (module: string) => string;
export function getOssAssetsGenerator(module: string) : OssURLGenerator {
  return (path: string): string => getOssAssets(module + path);
}